













import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import __ from '@/shared/helpers/__'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import Violation from '@/shared/modules/violation/violation.model'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import DataTableFilter, { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import permissions from '@/shared/helpers/permissions.helper'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AddViolationDialog from '@/company/components/violations/AddViolationDialog.vue'
import EditViolationDialog from '@/company/components/violations/EditViolationDialog.vue'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import ViolationService from '@/shared/modules/violation/violation.service'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import Company from '@/shared/modules/company/company.model'
import can from '@/shared/helpers/can.helper'

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class HseViolationsIndex extends Vue {
  table: DataTableBase = new DataTableBase()
    .setModel(Violation)
    .setEndpoint(`company/${ this.company.slug }/violations`)
    .setDoubleClick(this.editViolation)
    .setHeaders([
      new DataTableHeader()
        .setKey('unique_id')
        .setEntryKey('uniqueId')
        .setText(__('company.views.projects.show.tabs.violations.table.headers.unique-id')),
      new DataTableHeader()
        .setKey('project.name')
        .setText(__('company.views.projects.show.tabs.violations.table.headers.project')),
      new DataTableHeader()
        .setKey('name')
        .setText(__('company.views.projects.show.tabs.violations.table.headers.name')),
      new DataTableHeader()
        .setKey('description')
        .setText(__('company.views.projects.show.tabs.violations.table.headers.description')),
      new DataTableHeader()
        .setKey('seen_at')
        .setEntryKey('seenAt')
        .setText(__('company.views.projects.show.tabs.violations.table.headers.seen-at')),
      new DataTableHeader()
        .setKey('deadline')
        .setText(__('company.views.projects.show.tabs.violations.table.headers.deadline')),
      new DataTableHeader()
        .setKey('type.name')
        .setText(__('company.views.projects.show.tabs.violations.table.headers.type'))
        .setSortable(false),
      new DataTableHeader()
        .setKey('author.full_name')
        .setEntryKey('author.fullName')
        .setText(__('company.views.projects.show.tabs.violations.table.headers.author'))
        .setSortable(false),
      new DataTableHeader()
        .setKey('responsible.full_name')
        .setEntryKey('responsible.fullName')
        .setText(__('company.views.projects.show.tabs.violations.table.headers.responsible'))
        .setSortable(false),
      new DataTableHeader()
        .setKey('status.name')
        .setEntryKey('status.name')
        .setText(__('company.views.projects.show.tabs.violations.table.headers.status'))
        .setSortable(false)
    ])
    .setActions([
      new DataTableAction()
        .setIcon('mdi-pencil')
        .setPermissions([ permissions.company.violations.update, permissions.company.violations.show ])
        .setTitle(__('company.views.projects.show.tabs.violations.table.action.edit'))
        .setAction(this.editViolation),
      new DeleteAction()
        .setPermissions([ permissions.company.violations.delete ])
        .setAction(this.deleteViolation)
    ])
    .setDefaultFilters([
      {
        operator: FilterOperators.equals,
        key: 'type-uuid',
        value: 'ds',
      }
    ])

  addViolation(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AddViolationDialog,
      persistent: true,
      maxWidth: 980,
      meta: {
        isFromHSE: true,
        onSuccess: () => {
          const table: any = this.$refs.table
          table.refresh()
        }
      }
    })
  }

  editViolation(violation: Violation): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: EditViolationDialog,
      persistent: true,
      maxWidth: 980,
      meta: {
        isFromHSE: true,
        violation,
        onSuccess: (entry: Violation) => {
          this.table.updateItem(entry)
        }
      }
    })
  }

  deleteViolation(violation: Violation): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('company.views.projects.show.tabs.violations.table.action.delete.title'),
        text: __('company.views.projects.show.tabs.violations.table.action.delete.text', { name: violation.name }),
        onYes: () => ViolationService.destroy(this.company, violation)
          .then(() => {
            this.table.removeItem(violation)
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __('company.views.projects.show.tabs.violations.table.action.delete.on-success')
            })
          })
      }
    })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
